import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Picker from '@emoji-mart/react';
import InfiniteScroll from 'react-infinite-scroll-component';

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const emojiPickerRef = useRef(null);

    const fetchPosts = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts/approved`, {
                params: { page, limit: 10 }
            });
            setPosts(prevPosts => [...prevPosts, ...response.data.posts]);
            setHasMore(response.data.hasMore);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    }, [page]);

    useEffect(() => {
        fetchPosts();

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [fetchPosts]);

    const fetchMorePosts = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        if (page > 1) {
            fetchPosts();
        }
    }, [page, fetchPosts]);

    const handleOutsideClick = (event) => {
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
            setShowEmojiPicker(false);
        }
    };

    const handleReaction = async (postId, emoji) => {
        const updatedPosts = posts.map(post => {
            if (post._id === postId) {
                const existingReaction = post.reactions.find(reaction => reaction.emoji === emoji);
                let newReactions;
                if (existingReaction) {
                    newReactions = post.reactions.map(reaction =>
                        reaction.emoji === emoji ? { ...reaction, count: reaction.count + 1 } : reaction
                    );
                } else {
                    newReactions = [...post.reactions, { emoji, count: 1 }];
                }
                return {
                    ...post,
                    reactions: newReactions,
                };
            }
            return post;
        });
        setPosts(updatedPosts);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/posts/reaction`, {
                postId,
                emoji,
            });
        } catch (error) {
            console.error('Error reacting to post:', error);
            const revertedPosts = posts.map(post => {
                if (post._id === postId) {
                    const existingReaction = post.reactions.find(reaction => reaction.emoji === emoji);
                    let newReactions;
                    if (existingReaction && existingReaction.count > 1) {
                        newReactions = post.reactions.map(reaction =>
                            reaction.emoji === emoji ? { ...reaction, count: reaction.count - 1 } : reaction
                        );
                    } else {
                        newReactions = post.reactions.filter(reaction => reaction.emoji !== emoji);
                    }
                    return {
                        ...post,
                        reactions: newReactions,
                    };
                }
                return post;
            });
            setPosts(revertedPosts);
        }
    };

    const handleEmojiSelect = (emoji) => {
        if (selectedPostId) {
            handleReaction(selectedPostId, emoji.native);
            setShowEmojiPicker(false);
            setSelectedPostId(null);
        }
    };

    const toggleEmojiPicker = (postId) => {
        setSelectedPostId(postId);
        setShowEmojiPicker(!showEmojiPicker);
    };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    return (
        <div className="flex justify-center items-center flex-col">
            <h1 className="text-4xl font-bold text-center mb-8">Wall of Shame</h1>
            <div className="w-full max-w-xl">
                <InfiniteScroll
                    dataLength={posts.length}
                    next={fetchMorePosts}
                    hasMore={hasMore}
                    loader={<p>Loading...</p>}
                    endMessage={<p>No more data to load.</p>}
                >
                    <ul>
                        {posts.map(post => (
                            <div key={post._id} className="bg-white shadow-md rounded p-4 mb-4">
                                {post.content.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                    <img src={post.content} className="w-64 h-64" alt="Post" />
                                ) : (
                                    <p className="text-gray-800">{post.content}</p>
                                )}
                                <p className="text-gray-600 text-sm mt-2">
                                    {new Date(post.createdAt).toLocaleString()}
                                </p>
                                <div className="text-gray-600 text-sm mt-2">
                                    {post.reactions.map(reaction => (
                                        <span key={reaction.emoji} className="mr-2">
                                            {reaction.emoji} {reaction.count}
                                        </span>
                                    ))}
                                </div>
                                <div className="flex space-x-4 mt-2">
                                    {/* Common emojis for quick selection */}
                                    <button className="text-2xl" onClick={() => handleReaction(post._id, '👍')}>
                                        👍
                                    </button>
                                    <button className="text-2xl" onClick={() => handleReaction(post._id, '👎')}>
                                        👎
                                    </button>
                                    <button className="text-2xl" onClick={() => handleReaction(post._id, '😂')}>
                                        😂
                                    </button>
                                    <button className="text-2xl" onClick={() => handleReaction(post._id, '😮')}>
                                        😮
                                    </button>
                                    {/* Button to open emoji picker */}
                                    <button className="text-2xl" onClick={(e) => { toggleEmojiPicker(post._id); stopPropagation(e); }}>
                                        Open Emoji Picker
                                    </button>
                                </div>
                                {showEmojiPicker && selectedPostId === post._id && (
                                    <div ref={emojiPickerRef}>
                                        <Picker onEmojiSelect={handleEmojiSelect} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </ul>
                </InfiniteScroll>
                {posts.length === 0 && <p>No approved posts yet.</p>}
            </div>
        </div>
    );
};

export default Home;
