import React, { useState } from 'react';
import axios from 'axios';

const Upload = () => {
    const [file, setFile] = useState(null);
    const [sport, setSport] = useState('');
    const [customSport, setCustomSport] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleSportChange = (e) => {
        setSport(e.target.value);
        if (e.target.value !== 'Other') {
            setCustomSport('');
        }
    };

    const handleCustomSportChange = (e) => {
        setCustomSport(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file || !sport || (sport === 'Other' && !customSport)) {
            alert('Please select a file and choose a sport');
            return;
        }

        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('sport', sport === 'Other' ? customSport : sport);

            await axios.post('http://localhost:8080/api/v0/posts', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            alert('Upload successful!');
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Upload failed. Please try again.');
        } finally {
            setIsLoading(false);
            setFile(null);
            setSport('');
            setCustomSport('');
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-8 bg-gray-100 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Image/Video Upload</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="file" className="block text-gray-700 font-bold mb-2">
                        Choose File
                    </label>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                        className="border rounded p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="sport" className="block text-gray-700 font-bold mb-2">
                        Sport
                    </label>
                    <select
                        id="sport"
                        name="sport"
                        value={sport}
                        onChange={handleSportChange}
                        className="border rounded p-2 w-full"
                    >
                        <option value="" disabled>Select a sport</option>
                        <option value="ATP">ATP Tennis</option>
                        <option value="WTA">WTA Tennis</option>
                        <option value="NBA">NBA</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                {sport === 'Other' && (
                    <div className="mb-4">
                        <label htmlFor="customSport" className="block text-gray-700 font-bold mb-2">
                            Specify Sport
                        </label>
                        <input
                            type="text"
                            id="customSport"
                            name="customSport"
                            value={customSport}
                            onChange={handleCustomSportChange}
                            className="border rounded p-2 w-full"
                        />
                    </div>
                )}
                <button
                    type="submit"
                    className={`bg-blue-500 text-white py-2 px-4 rounded ${
                        isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                    }`}
                    disabled={isLoading}
                >
                    {isLoading ? 'Uploading...' : 'Upload'}
                </button>
            </form>
        </div>
    );
};

export default Upload;
