import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Admin = ({ user }) => {
    const logout = () => {
        window.open(`${process.env.REACT_APP_API_URL}/auth/logout`, "_self");
    };

    const [unapprovedPosts, setUnapprovedPosts] = useState([]);
    const [currentPostIndex, setCurrentPostIndex] = useState(0);
    const [formData, setFormData] = useState({
        platform: '',
        accountHandle: '',
        accountName: '',
    });

    useEffect(() => {
        const fetchUnapprovedPosts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts/unapproved`, { withCredentials: true });
                setUnapprovedPosts(response.data);
            } catch (error) {
                console.error('Error fetching unapproved posts:', error);
            }
        };

        fetchUnapprovedPosts();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const approvePost = async (postId, approval) => {
        try {
            if (approval && (!formData.platform || !formData.accountHandle || !formData.accountName)) {
                alert('Please fill in all fields');
                return;
            }

            const payload = approval ? {
                approved: approval,
                platform: formData.platform,
                accountHandle: formData.accountHandle,
                accountName: formData.accountName,
            } : { approved: approval };

            await axios.patch(`${process.env.REACT_APP_API_URL}/posts/${postId}/approve`, payload, { withCredentials: true });
            const updatedPosts = unapprovedPosts.filter(post => post._id !== postId);
            setUnapprovedPosts(updatedPosts);
            if (updatedPosts.length > 0) {
                setCurrentPostIndex(currentPostIndex % updatedPosts.length);
            } else {
                setCurrentPostIndex(0);
            }
        } catch (error) {
            console.error('Error approving post:', error);
        }
    };

    const goToNextPost = () => {
        setCurrentPostIndex((currentPostIndex + 1) % unapprovedPosts.length);
    };

    const goToPreviousPost = () => {
        setCurrentPostIndex((currentPostIndex - 1 + unapprovedPosts.length) % unapprovedPosts.length);
    };

    const currentPost = unapprovedPosts[currentPostIndex];

    return (
        <div className="flex justify-center items-center flex-col">
            <h1 className="text-4xl font-bold text-center mb-8">Admin</h1>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md w-80">
                <div className="flex items-center space-x-4">
                    <h2 className="text-xl font-semibold">{user.name}</h2>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                        onClick={logout}
                    >
                        Log Out
                    </button>
                </div>
            </div>

            <div className="mt-8 w-full max-w-xl">
                <h2 className="text-2xl font-bold mb-4">Review Post</h2>
                {unapprovedPosts.length === 0 ? (
                    <p>No unapproved posts.</p>
                ) : (
                    <div>
                        <div className="flex justify-between mb-4">
                            <button
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                onClick={goToPreviousPost}
                            >
                                Previous
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                onClick={goToNextPost}
                            >
                                Next
                            </button>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 mb-4">
                            <img src={currentPost.content} className="w-64 h-64" alt="Post Content" />
                            <p className="mt-2 mb-4"><strong>Sport:</strong> {currentPost.sport}</p>
                            <form className="mt-2">
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="platform">Platform</label>
                                    <select
                                        id="platform"
                                        name="platform"
                                        value={formData.platform}
                                        onChange={handleInputChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    >
                                        <option value="">Select Platform</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="X/Twitter">X/Twitter</option>
                                        <option value="Facebook">Facebook</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountHandle">Account Handle</label>
                                    <input
                                        id="accountHandle"
                                        name="accountHandle"
                                        type="text"
                                        value={formData.accountHandle}
                                        onChange={handleInputChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountName">Account Name</label>
                                    <input
                                        id="accountName"
                                        name="accountName"
                                        type="text"
                                        value={formData.accountName}
                                        onChange={handleInputChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="flex">
                                    <button
                                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mr-2"
                                        type="button"
                                        onClick={() => approvePost(currentPost._id, true)}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                        type="button"
                                        onClick={() => approvePost(currentPost._id, false)}
                                    >
                                        Archive
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Admin;
