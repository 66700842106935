const Login = () => {
	const googleAuth = () => {
		window.open(`${process.env.REACT_APP_API_URL}/auth/google/callback`, "_self");
	};

	return (
		<div className="flex justify-center items-center flex-col">
			<h1 className="text-4xl font-bold text-center mb-8">Admin Login</h1>
			<div className="bg-white p-6 rounded-lg shadow-md">
				<button
					className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
					onClick={googleAuth}
				>
					<img src="./images/google.png" alt="Google Icon" className="w-6 h-6 mr-2" />
					<span>Sign in with Google</span>
				</button>
			</div>
		</div>
	);
};

export default Login;
  